"use strict";

document.addEventListener('DOMContentLoaded', function () {
  $(document).foundation();
  var swiper = new Swiper(".swiper-product-thumbs", {
    spaceBetween: 10,
    slidesPerView: 5,
    freeMode: true,
    watchSlidesProgress: true
  });
  var swiper2 = new Swiper(".swiper-product-image", {
    spaceBetween: 10,
    autoHeight: true,
    navigation: {
      nextEl: ".swiper-product-next",
      prevEl: ".swiper-product-prev"
    },
    a11y: {
      prevSlideMessage: "Previous slide",
      nextSlideMessage: "Next slide"
    },
    keyboard: {
      enabled: true
    },
    thumbs: {
      swiper: swiper
    }
  });
  $('.magnific-popup').magnificPopup({
    type: 'image',
    mainClass: ' mfp-with-zoom',
    removalDelay: 300,
    gallery: {
      enabled: true
    }
  });
  function updateTotals() {
    var priceElement = document.querySelector('.price');
    var metersElement = document.querySelector('.meters');
    var inputTotalSize = document.getElementById('input-total-size');
    var packsNeeded = document.getElementById('packs-needed');
    var inputTotalLoss = document.getElementById('input-total-loss');
    var totalSizeElement = document.getElementById('total-size');
    var totalPriceElement = document.getElementById('total-price');
    var quoteButtons = document.querySelectorAll('.quote-button');
    if (!priceElement || !metersElement || !inputTotalSize || !packsNeeded || !inputTotalLoss || !totalSizeElement || !totalPriceElement || quoteButtons.length === 0) {
      console.warn('Required elements are not present on the page.');
      return;
    }
    var pricePerSquareMeter = parseFloat(priceElement.getAttribute('data-price')) || 0;
    var sizePerPack = parseFloat(metersElement.getAttribute('data-size')) || 1;
    var includeLoss = inputTotalLoss.checked;
    var lossPercentage = parseFloat(inputTotalLoss.getAttribute('data-volume')) || 15;
    var sizeNeeded = parseFloat(inputTotalSize.value) || 0;
    var packs;

    // Check which element is being updated
    if (packsNeeded === document.activeElement) {
      // If the user updates the packs, calculate the price based on packs but keep sizeNeeded unchanged
      packs = parseInt(packsNeeded.value) || 0;
    } else {
      // Calculate packs based on the total size input
      if (includeLoss) {
        sizeNeeded *= 1 + lossPercentage / 100;
      }
      packs = Math.ceil(sizeNeeded / sizePerPack);
      packsNeeded.value = packs; // Update the packs input
    }
    totalSizeElement.textContent = sizeNeeded.toFixed(2);

    // Calculate the total price based on packs
    var totalPrice = packs * pricePerSquareMeter * sizePerPack;
    totalPriceElement.textContent = totalPrice.toFixed(2).replace('.', ',');

    // Update quote button URLs with size and price parameters
    quoteButtons.forEach(function (button) {
      var url = new URL(button.href);
      url.searchParams.set('size', sizeNeeded.toFixed(2));
      url.searchParams.set('price', totalPrice.toFixed(2));
      button.href = url.toString();
    });
  }
  function attachEventListeners() {
    var inputTotalSize = document.getElementById('input-total-size');
    var packsNeeded = document.getElementById('packs-needed');
    var inputTotalLoss = document.getElementById('input-total-loss');
    if (inputTotalSize) {
      inputTotalSize.addEventListener('input', updateTotals);
    }
    if (packsNeeded) {
      packsNeeded.addEventListener('input', updateTotals);
    }
    if (inputTotalLoss) {
      inputTotalLoss.addEventListener('change', updateTotals);
    }

    // Initialize totals on page load
    updateTotals();
  }
  attachEventListeners();
  var nav = document.getElementById('site-navigation');
  var menuBtn = document.querySelector('.menu-btn');
  var lines = document.querySelectorAll('.menu-btn .line');
  var ul = document.querySelectorAll("nav#site-navigation ul li ul");
  var li = document.querySelectorAll("nav#site-navigation ul li ul li");
  var openSearchBtn = document.querySelector('.open-search-from');
  var deleteSearchBtn = document.querySelector('#deleteSearch');
  var fadeElements = document.querySelectorAll('.fade-in');
  var header = document.querySelector('header');
  var navbarHeight = header.offsetHeight;
  var didScroll = false;
  var lastScrollTop = 0;
  var delta = 5;
  menuBtn.addEventListener('click', function () {
    nav.classList.toggle('open-nav');
    lines.forEach(function (line) {
      return line.classList.toggle('line-cross');
    });
    lines[1].classList.toggle('line-fade-out');
  });
  function isScrolledIntoView(elem) {
    var docViewTop = window.scrollY;
    var docViewBottom = docViewTop + window.innerHeight + 200;
    var elemTop = elem.offsetTop;
    var elemBottom = elemTop + elem.offsetHeight;
    return elemBottom <= docViewBottom && elemTop >= docViewTop;
  }
  ul.forEach(function (ul) {
    if (ul.classList.contains('children')) {
      ul.parentNode.classList.add('hasChildren');
    }
  });
  li.forEach(function (li) {
    if (li.classList.contains('hasChildren')) {
      li.querySelector('a').insertAdjacentHTML('beforeend', "<a href='javascript:void(0)' class='expandChild' title='Child item'></a>");
    }
  });
  document.addEventListener('click', function (e) {
    if (!nav.contains(e.target)) {
      nav.classList.remove('nav-open');
    }
  });
  openSearchBtn.addEventListener('click', function () {
    document.querySelector('.top-search').classList.add('open-search');
    document.body.classList.add('search-main');
  });
  deleteSearchBtn.addEventListener('click', function () {
    document.querySelector('.top-search').classList.remove('open-search');
    document.body.classList.remove('search-main');
  });
  window.addEventListener('scroll', function () {
    fadeElements.forEach(function (element) {
      if (isScrolledIntoView(element)) {
        element.classList.add('animation-started');
      }
    });
    didScroll = true;
  });
  setInterval(function () {
    if (didScroll) {
      hasScrolled();
      didScroll = false;
    }
  }, 250);
  function hasScrolled() {
    var st = window.scrollY;
    if (Math.abs(lastScrollTop - st) <= delta) return;
    if (st > lastScrollTop && st > navbarHeight) {
      header.classList.remove('nav-down');
      header.classList.add('nav-up');
    } else {
      if (st + window.innerHeight < document.body.offsetHeight) {
        header.classList.remove('nav-up');
        header.classList.add('nav-down');
      }
    }
    lastScrollTop = st;
  }
});