"use strict";

$(document).ready(function () {
  var swiper = new Swiper(".swiper-container", {
    slidesPerView: 1.5,
    loop: true,
    grabCursor: true,
    spaceBetween: 20,
    watchSlidesProgress: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    },
    breakpoints: {
      // when window width is >= 320px
      640: {
        slidesPerView: 3,
        spaceBetween: 32
      }
    }
  });
});